import React from 'react'
import { ProgressSpinner } from 'primereact/progressspinner';
const ProgressSpinnerLo = () => {
  return (
    <div className="d-flex justify-content-center align-items-center w-100 h-100">
            <ProgressSpinner />
        </div>
  )
}

export default ProgressSpinnerLo